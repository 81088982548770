import React from "react"
import { Link } from "gatsby"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { ExternalLink } from "../components/ds"

const Page = () => {
  return (
    <>
      <SEO title="Vanliga frågor" />
      <Layout>
        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
            <h2 className="text-3xl leading-9 font-extrabold text-warm-grey-900">
              Vanliga frågor
            </h2>
            <div className="mt-6 border-t-2 border-warm-grey-100 pt-10">
              <dl className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <div>
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Vad kostar det?
                    </dt>
                    <p className="text-base leading-6 text-warm-grey-500">
                      Man betalar olika avgift beroende på hur många timmar
                      barnet är på förskolan, det påverkas också om barnet
                      omfattas av allmän förskola. Vi har en högsta taxa på 900
                      kr, sedan så är det reduktioner beroende på antal timmar
                      barnet vistas på förskolan, och om barnet omfattas av
                      allmän förskola, då är barn 3-5 år avgiftsfri 1 september
                      - 31 maj. För barn 3-5 år som har allmän förskoleplats
                      året om, reduceras avgiften med 200 kr. Vi har även en
                      medlemsavgift på 50 kronor per medlem och år. Kontakta oss
                      på{" "}
                      <ExternalLink
                        href="mailto: styrelsen@forskolanbrandbilen.se"
                        target="_top"
                      >
                        styrelsen@forskolanbrandbilen.se
                      </ExternalLink>{" "}
                      för mer information.
                    </p>
                    <dd className="mt-2">
                      <p className="text-large font-semibold leading-6 text-warm-grey-500 pb-6 mt-4">
                        Under 15h/v
                      </p>
                      <CostTable
                        rows={[
                          {
                            title: "Barn under 3 år",
                            cost: "400kr/månad",
                          },
                          {
                            title: "Barn över 3 år",
                            cost: "0kr/mån",
                          },
                        ]}
                      />
                      <p className="text-large font-semibold leading-6 text-warm-grey-500 pb-6 mt-6">
                        Över 15h/v
                      </p>
                      <CostTable
                        rows={[
                          {
                            title: "Barn under 3 år",
                            cost: "900kr/månad",
                          },
                          {
                            title: "Barn över 3 år",
                            cost: "700kr/mån",
                          },
                        ]}
                      />
                    </dd>
                  </div>
                  <div className="mt-12">
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Hur söker jag till Brandbilen?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        En intresseanmälan gör du{" "}
                        <Link to="/intresseanmalan">på vår hemsida</Link>, eller
                        på kommunens hemsida. Vi har samma kösystem som kommunen
                        så anmälan måste alltid lämnas där. Du är varmt
                        välkommen att kontakta oss om du har frågor.
                      </p>
                    </dd>
                  </div>
                  <div className="mt-12">
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Vem svarar på frågor jag inte fått svar på?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        Du kan vända dig till personalen som jobbar på förskolan
                        eller till styrelsen på{" "}
                        <ExternalLink
                          href="mailto: styrelsen@forskolanbrandbilen.se"
                          target="_top"
                        >
                          styrelsen@forskolanbrandbilen.se
                        </ExternalLink>
                        .
                      </p>
                    </dd>
                  </div>
                  <div className="mt-12">
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Vem tjänar pengar på förskolan?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        Förskolan är ett kooperativ. Finns inget vinstdrivande
                        syfte. Finns det pengar över återinvesteras dessa i
                        verksamheten. Vi vill hålla en hög kvalitet med mindre
                        barngrupper.
                      </p>
                    </dd>
                  </div>
                </div>
                <div className="mt-12 md:mt-0">
                  <div>
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Kan jag jobba heltid och ändå ha mina barn på kooperativ?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        Det är vanligt med heltidsjobb bland vårdnadshavare och
                        alla bidrar på olika sätt efter tid och förmåga.
                      </p>
                    </dd>
                  </div>
                  <div className="mt-12">
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Vilka öppettider har förskolan?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        Förskolan anpassar öppettiderna utifrån barnens
                        vistelsetider. Vi vill på vår förskola använda
                        resurserna på ett bra sätt så det gagnar barnen.
                        <br />
                        <br />
                        Förskola tillhandahålls enligt kommunens krav alla
                        helgfria vardagar förutom julafton, nyårsafton och
                        midsommarafton inom ramtiden 12 timmar.
                        <br />
                        <br />
                        Förskolan har stängt 3-4 dagar per år för
                        verksamhetsutveckling
                      </p>
                    </dd>
                  </div>
                  <div className="mt-12">
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Kan jag ha mitt barn på förskolan kvällstid och på helger?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        Nej, tyvärr så erbjuder vi inte det.
                      </p>
                    </dd>
                  </div>
                  <div className="mt-12">
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Hur stor är förskolan? Hur många avdelningar och barn har
                      ni?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        Förskolan är en liten genuin förskola med trivsam
                        utemiljö och anpassade lokaler. Vi har cirka 20-25 barn
                        fördelat på två avdelningar som heter Larmet och
                        Sirenen.
                      </p>
                    </dd>
                  </div>
                  <div className="mt-12">
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Vad betyder kooperativ?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        Kooperativ är en form av ekonomisk samverkan, där vi
                        medlemmar både äger och driver förskolan brandbilen. Vi
                        ansvarar för de behov som finns, och den verksamhet vi
                        bedriver i vårt kooperativ. Vårt mål är att ge oss
                        medlemmarna och barn största möjliga nytta i att bedriva
                        en hållbar förskola.
                      </p>
                    </dd>
                  </div>
                  <div className="mt-12">
                    <dt className="text-lg leading-6 font-medium text-warm-grey-900">
                      Varför kooperativ istället för kommunal förskola?
                    </dt>
                    <dd className="mt-2">
                      <p className="text-base leading-6 text-warm-grey-500">
                        Ett kooperativ kan vara lämpligt om du vill att ditt
                        barn ska få vistas på en mindre förskola. Om du vill
                        vara med och få inblick och god gemenskap med personal
                        och andra vårdnadshavare.
                      </p>
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

type CostTable = {
  rows: {
    title: string
    cost: string
  }[]
}
const CostTable = ({ rows }: CostTable) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-warm-grey-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-warm-grey-200 bg-warm-grey-50 text-left text-xs leading-4 font-medium text-warm-grey-500 uppercase tracking-wider">
                  Ålder
                </th>
                <th className="px-6 py-3 border-b border-warm-grey-200 bg-warm-grey-50 text-left text-xs leading-4 font-medium text-warm-grey-500 uppercase tracking-wider">
                  Kostnad
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-warm-grey-200 text-sm leading-5 font-medium text-warm-grey-900">
                    {row.title}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-warm-grey-200 text-sm leading-5 text-warm-grey-500">
                    {row.cost}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Page
